


































































import Vue from "vue";
import api from "@/core/utils/api";
import { Company, Employee } from "@/core/models";
import { Action, Getter } from "vuex-class";
import { Component, Watch } from "vue-property-decorator";

type Invitation = {
  userEmail: string;
  company: Company;
  isManager: boolean;
};

@Component
export default class CompanyHome extends Vue {
  @Getter("company/getting") getting!: boolean;
  @Getter("company/company") company!: Company;
  @Getter("company/employees") employees!: Employee;
  @Getter("company/isOwner") isOwner!: boolean;
  @Getter("company/isEmployee") isEmployee!: boolean;
  @Getter("profile/ownsCompany") ownsCompany!: boolean;
  @Getter("company/isActive") isActive!: boolean;
  @Getter("company/hasCompany") hasCompany!: boolean;
  @Action("company/set") setCompany!: (company: Company) => void;

  loading = false;
  invitations: Invitation[] = [];
  async getInvitations() {
    const end = "/api/Company/Invitations";
    const res = (await api.get(end)) as Invitation[];
    this.invitations = res.map(x => {
      if (x.company) x.company = new Company(x.company);
      return x;
    });
  }
  async accept(invitation: Invitation) {
    this.loading = true;
    const end = "/api/Company/AcceptInvitation";
    const data = {
      companyId: invitation.company.ID,
    };
    try {
      const res = (await api.patch(end, data)) as any;
      this.setCompany(new Company(res));
      this.$router.replace("/");
    } catch (error) {
      console.log(error);
    }
    this.loading = false;
    // location.reload();
  }

  async getCompany() {
    const end = "/api/Company/GetCompanyOfUser";
    const res = (await api.get(end)) as Company[];
    return res;
  }

  created() {
    this.getInvitations();
    this.getCompany();
  }

  @Watch("hasCompany", { immediate: true })
  redirect() {
    const dashPath = "/company/dashboard";
    //if (this.hasCompany && this.isOwner) this.$router.push(dashPath);
    if (this.hasCompany) this.$router.push(dashPath);
  }
}
